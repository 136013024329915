import { Box, Card, PasswordInput, Stack, Text } from '@mantine/core';
import classes from './Login.module.scss';
import { PebbleButtonSet, PebbleTextInput } from 'components/ui';
import { useState } from 'react';
import { Auth } from '@pebble/common';
import { useForm } from '@mantine/form';
import Link from 'next/link';
import { useRouter } from 'next/router';
import qs from 'query-string';
import { PebbleButtonsEnum } from 'enums';

type FormValues = {
  phoneNumber: string;
  password: string;
};

const formatPhoneNumber = (phoneNumber: string): string => {
  const firstThreeChar = phoneNumber.substring(0, 3);
  const firstTwoChar = phoneNumber.substring(0, 2);

  //Check for th UK country code
  if (firstThreeChar === '+44') {
    return phoneNumber;
  }

  //Check for leading 07 - UK unformatted phone numbers
  if (firstTwoChar === '07') {
    phoneNumber = phoneNumber.substring(1);
    return `+44${phoneNumber}`;
  }

  return '';
};

const telRegEx = new RegExp(/(^(?=[0-9]{11}$)(0)\d+)|(^(?=[0-9]{10}$)(7)\d+)/);

export const Login: React.FC = () => {
  const router = useRouter();
  const { redirect, ...rest } = router.query;
  const [serverError, setServerError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm({
    initialValues: {
      phoneNumber: '',
      password: '',
    },
    validate: {
      phoneNumber: (value) => (telRegEx.test(value) ? null : 'Please enter a valid phone number'),
    },
  });

  const getLink = (route: string) => {
    if (redirect) {
      const params = qs.stringify({
        redirect,
        ...rest,
      });
      return `/${route}?${params}`;
    }

    return `/${route}`;
  };

  const handleSubmit = async (values: FormValues) => {
    const { phoneNumber, password } = values;
    if (serverError) {
      setServerError('');
    }
    setIsSubmitting(true);
    try {
      await Auth.login(formatPhoneNumber(phoneNumber), password);

      if (redirect) {
        const params = qs.stringify(rest);
        router.push(`/${redirect}${params ? `?${params}` : ''}`);
      } else {
        router.push('/');
      }
    } catch (e) {
      setServerError('This phone number or password is not valid. Please try again');
      form.setErrors({
        email: true,
        password: true,
      });
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <Box className={classes.container}>
        <Card shadow="sm" maw={400} className={classes.card}>
          <Stack>
            <Text fw={700} size="lg">
              Welcome back!
            </Text>
            <PebbleTextInput label="Phone number" {...form.getInputProps('phoneNumber')} />
            <PasswordInput
              label="Password"
              {...form.getInputProps('password')}
              classNames={{ input: classes.password, label: classes.label }}
            />
            {serverError && (
              <Text size="sm" c="red" role="alert">
                {serverError}
              </Text>
            )}
            <PebbleButtonSet
              type="submit"
              loading={isSubmitting}
              btnVariant={PebbleButtonsEnum.PRIMARY}
              size="md"
              w="100%"
            >
              Login
            </PebbleButtonSet>
            <Text size="sm">
              Don't have an account yet? <Link href={getLink('register')}>Register</Link>
            </Text>
            <Link href={getLink('reset-password')}>Forgot password?</Link>
          </Stack>
        </Card>
      </Box>
    </form>
  );
};
